@import "../util/style.scss";

.wrapper {
  @media screen and (min-width: $ipad-width) {
    height: calc(var(--new100vh) - 4rem);
  }
  padding: 2rem 1rem;
}

.background {
  width: 100%;
  height: 100%;
  filter: blur(20px);
}

.body {
  max-width: 1024px;
  width: 100%;
  height: 100%;
  min-height: 600px;
  // overflow: hidden;
}

.frame {
  &-title {
    padding-top: 4rem;
    width: 100%;
  }
  &-content {
    width: 100%;
    // padding: 1rem 0 0 0;
    height: calc(100% - 1rem);

    max-height: 720px;
    min-height: 0;
    // flex-grow: 1;
    flex-shrink: 1;
  }
  &-map {
    width: 75%;
    height: 100%;
    @media screen and (max-width: $ipad-width) {
      min-width: calc(100vw - 2rem);
      height: calc(min(calc(100vw - 2rem), 60vh));
    }
    background-color: $col-g-l;
    border: 1.5px solid $col-g;
    border-radius: 1rem;
    position: relative;
    overflow: hidden;
    z-index: 0;
    &-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 2;
      pointer-events: none;
    }
  }
  &-info {
    width: 25%;
    min-width: 300px;
    @media screen and (min-width: $ipad-width) {
      height: 100%;
    }
    @media screen and (max-width: $ipad-width) {
      width: calc(100vw - 2rem);
      min-width: calc(100vw - 2rem);
      & > * > :nth-child(1) {
        height: 30vh;
      }
      & > * > :nth-child(2) {
        height: 60vh;
      }
      padding-bottom: 2rem;
    }
  }
  &-div {
    width: 100%;

    height: calc(50% - 0.5rem - 1.5px);

    background-color: $col-g-l;
    border: 1.5px solid $col-g;
    border-radius: 1rem;
  }
  &-scroll {
    width: 100%;
    min-height: 0;
    flex-grow: 1;
    flex-shrink: 1;
    max-height: 100%;
    overflow-y: auto;
    padding-right: 0.2rem;
    &::-webkit-scrollbar {
      width: 6px;

      &-thumb {
        background: $col-g;
        border-radius: 1rem;
        &:hover {
          background: $col-g-f;
        }
      }
    }
  }
  &-loading {
    background: $col-g;
  }
  &-icon {
    cursor: pointer;
    transition: all 0.2s;
    transform: rotate(-180deg);
    & * {
      stroke: $col-g-f;
    }
    &.close {
      transform: rotate(0deg);
    }
  }
  &-subsection {
    width: calc(100% - 1rem);
    padding: 0.25rem 0.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all 0.3s;
    @media (pointer: fine) {
      &:hover {
        background-color: $col-s-l;
      }
    }
  }
  &-dropdown {
    overflow: hidden;
    width: 100%;
  }
  &-picker {
    z-index: 2;
    position: absolute;
    top: calc(100% - 1px);
    left: 40%;
    transform: translateX(-50%);
  }
}

.text {
  &-title {
    @include txt-title-bold;
    color: $col-p-d;
  }
  &-illust {
    padding-left: 0.25rem;
    @include txt-text;
    color: $col-g-f;
    word-break: keep-all;
  }
  &-section {
    width: calc(100% - 1rem);
    padding: 0.5rem;
    @include txt-section-title;
  }
  &-subsection {
    color: $col-g-f;
  }

  &-alert {
    @include txt-logo;
    color: $col-g-f;
  }
  &-error {
    @include txt-text;
    width: 100%;
    text-align: center;
    word-break: keep-all;
    color: $col-g-f;
    padding: 0.5rem 0;
    &.big {
      @include txt-logo;
      line-height: 1.5;
      color: $col-g-f;
      // filter: drop-shadow($col-w 0px 0px 3px);
    }
  }
}
