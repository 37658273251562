@import "../util/style.scss";

.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  @include column(100%, auto, 0px);
  height: calc(var(--newvh, 1vh) * 100);
  width: 100vw;
  justify-content: center;
}

.frame {
  &-logo {
    height: 7.5rem;

    @include column(auto, auto, 0.1rem);
    justify-content: center;
  }
}

.logo {
  width: 2rem;
  &-title {
    @include txt-logo-eng;
    color: $col-p-f;
    filter: brightness(1.1);
  }
}

.text {
  @include txt-title;
  text-align: center;
  &.emph {
    color: $col-p-f;
  }
}
