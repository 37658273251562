@import "../util/style.scss";

.wrapper {
  width: calc(100% - 4px);
  max-width: calc(100% - 4px);
  padding-right: 0.25rem;
  border-radius: 0.75rem;
  transition: all 0.3s, width 0s;
  min-width: 0;
  @media screen and (min-width: $ipad-width) {
    &.clickable {
      cursor: pointer;
      &:hover {
        background-color: $col-s-l;
      }
    }
  }
  &.small {
    width: max-content;
    border-radius: 1.75rem;
  }
  &.clicked {
    background-color: $col-p-l;
    @media screen and (min-width: $ipad-width) {
      &:hover {
        background-color: $col-p-l;
      }
    }
  }

  &.no-image {
    padding: 0.125rem 0.25rem 0.125rem 0.5rem;
  }
  // overflow: hidden;
}

.frame {
  &-text {
    padding: 0.25rem 0;
    flex-shrink: 1;
    flex-grow: 1;
    min-width: 0;
  }
  &-chip {
    padding: 0 0.25rem;
  }
  &-image {
    margin: 0.25rem;
    min-width: 52px;
    height: 52px;
    border-radius: 0.5rem;
    overflow: hidden;
    flex-grow: 0;
    // background-size: cover;
    // background-position: center;
    background-color: $col-w;
    &.image {
      border-radius: 0rem;
      background-color: #ffffffff;
    }
    &.small {
      min-width: 24px;
      height: 24px;
      border-radius: 1rem;
    }
  }
  &-hover {
    // position: absolute;
    background-color: $col-w;
    width: 100%;
    height: 100%;
  }
  &-content {
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;
  }
}

.image {
  object-fit: cover;
  margin: 0;
  padding: 0;
  &.hover {
    display: none;
  }
}

.text {
  &-title {
    @include txt-title;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: $col-s-d;
    width: 100%;
    &.small {
      width: auto;
      // overflow: visible;
      padding-right: 0.25rem;
      max-width: 100%;
    }
  }
  &-illust {
    @include txt-text-illust-small;
    color: $col-g-f;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &.small {
      display: none;
      overflow: visible;
    }
  }
}
