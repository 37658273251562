@import "../util/style.scss";

.frame {
  &-popup {
    // box-shadow: 0 19px 38px rgba(0, 0, 0, 0.1), 0 15px 12px rgba(0, 0, 0, 0.05);
    // -webkit-filter: drop-shadow(0 19px 38px rgba(0, 0, 0, 0.1))
    //   drop-shadow(0 15px 12px rgba(0, 0, 0, 0.05));
    filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
    //   drop-shadow(0 15px 12px rgba(0, 0, 0, 0.05));
  }
  &-image {
    position: relative;
    width: 2rem;
    height: 2rem;
    border-radius: 0.375rem;
    overflow: hidden;
    border: 2px solid $col-w;
    margin-right: 0.5rem;
    & > img {
      object-fit: cover;
    }
    &-not-exact {
      border-bottom: 3px dotted $col-w;
      width: 25%;
      position: absolute;
      top: calc(100% + 2px);
      left: 40%;
      transform: translateX(-50%);
    }
    &-overlay {
      position: absolute;
      left: 100%;
      top: 50%;
      cursor: auto;
      transform: translateY(-50%);
      width: calc(min(228px, 25vw));
      background-color: $col-w;
      border-radius: 0.75rem;
      box-shadow: 0 19px 38px rgba(0, 0, 0, 0.1),
        0 15px 12px rgba(0, 0, 0, 0.05);
      &-container {
        // padding: .5rem;
      }
      &-scroll {
        overflow-x: auto;
        margin: 0 0.5rem 0.25rem 0.5rem;
        padding-bottom: 0.25rem;
        cursor: grab;
        &::-webkit-scrollbar {
          height: 6px;
          &-thumb {
            background: $col-g;
            border-radius: 1rem;
            &:hover {
              background: $col-g-f;
            }
          }
        }
      }
    }
  }

  &-picker {
    position: absolute;
    top: calc(100% - 1px);
    left: 40%;
    transform: translateX(-50%);
  }
  &-like-small {
    position: absolute;
    top: 0;
    right: 0.5rem;
  }
}

.text {
  &-title {
    @include txt-title;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: $col-s-d;
    width: 100%;
    &.small {
      width: auto;
      overflow: visible;
      padding-right: 0.25rem;
      max-width: 100%;
    }
  }
  &-illust {
    @include txt-text-illust-small;
    color: $col-g-f;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &.small {
      display: none;
      overflow: visible;
    }
  }
}
