@import "../util/style.scss";

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  &.hidden {
    pointer-events: none;
  }
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  // transform: translate(-50%, -50%);
  background-color: $col-w;
  padding: 1rem;
  // box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.1), 0 15px 12px rgba(0, 0, 0, 0.05);
  border-radius: 1.5rem;
  z-index: 1000;
}
