@import "../util/style.scss";

.wrapper {
  height: calc(var(--new100vh) - 4rem);
  padding: 2rem 1rem;
}

.body {
  max-width: 1024px;
  width: 100%;
}

.frame {
  &-title {
    padding-top: 4rem;
    width: 100%;
  }
  &-section {
    padding: 2.5rem 0.25rem 0.5rem 0.25rem;
    width: calc(100% - 0.5rem);
    @include txt-section-title;
    text-align: left;
    line-height: 1.5;
    word-break: keep-all;
  }
  &-space {
    height: 2rem;
  }
}

.text {
  &-title {
    @include txt-title-bold;
    color: $col-p-d;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &-illust {
    padding-left: 0.25rem;
    @include txt-text;
    color: $col-g-f;
  }
}
