@import "../util/style.scss";

.wrapper-top {
  position: relative;
  @media screen and (min-width: $ipad-width) {
    height: var(--new100vh);
  }
}

.wrapper {
  // position: absolute;
  // top: 0;
  @media screen and (min-width: $ipad-width) {
    height: calc(var(--new100vh) - 4rem);
  }
  padding: 2rem 1rem;
  width: calc(100vw - 2rem);
  overflow-x: hidden;
}

.background {
  width: 100%;
  height: 100%;
  filter: blur(20px);
}

.body {
  max-width: 1024px;
  width: 100%;
  height: 100%;
  min-height: 600px;
  // overflow: hidden;
}

.frame {
  &-title {
    padding-top: 4rem;
    width: 100%;
  }
  &-content {
    width: 100%;
    padding: 1rem 0 0 0;
    @media screen and (min-width: $ipad-width) {
      height: calc(100% - 1rem);

      max-height: 720px;
      min-height: 0;
    }
    // flex-grow: 1;
    flex-shrink: 1;
  }
  &-image {
    width: 75%;
    height: 100%;
    background-color: $col-g-l;
    border: 1.5px solid $col-g;
    border-radius: 1rem;
    position: relative;
    overflow: hidden;
    @media screen and (max-width: $ipad-width) {
      min-width: calc(100vw - 2rem);
      height: calc(min(calc(100vw - 2rem), 60vh));
    }
    &-overlay {
      pointer-events: none;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
    &-dropdown {
      width: 100%;
      max-width: 330px;

      background-color: $col-s-l;

      padding: 0.25rem 0.5rem 0.5rem 0.5rem;
      @media screen and (max-width: $ipad-width) {
        padding: 0.5rem;
      }

      pointer-events: all;
      cursor: pointer;
      @media (pointer: fine) {
        &:hover {
          background-color: rgb(232, 232, 223);
        }
      }
      &-scroll {
        width: 100%;
        height: 100%;
        overflow-y: auto;

        margin-right: 0.25rem;

        &::-webkit-scrollbar {
          width: 6px;

          &-thumb {
            background: $col-s-f;
            border-radius: 1rem;
            &:hover {
              background: $col-g-f;
            }
          }
        }
      }
    }
  }
  &-icon {
    cursor: pointer;
    transition: all 0.3s;
    transform: rotate(-180deg);
    & * {
      stroke: $col-g-f;
    }
    &.close {
      transform: rotate(0deg);
    }
    margin-right: 0.5rem;
  }
  &-info {
    width: 25%;
    min-width: 300px;
    @media screen and (min-width: $ipad-width) {
      height: 100%;
    }
    @media screen and (max-width: $ipad-width) {
      width: calc(100vw - 2rem);
      min-width: calc(100vw - 2rem);
      & > * > :nth-child(2) {
        height: 60vh;
      }
      padding-bottom: 2rem;
    }
  }
  &-div {
    width: 100%;

    height: calc(50% - 0.5rem - 1.5px);

    background-color: $col-g-l;
    border: 1.5px solid $col-g;
    border-radius: 1rem;
  }
  &-scroll {
    width: 100%;
    height: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 6px;

      &-thumb {
        background: $col-g;
        border-radius: 1rem;
        &:hover {
          background: $col-g-f;
        }
      }
    }
  }
  &-loading {
    background: $col-g;
  }
  &-space {
    width: 100%;
    min-height: 0.5rem;
  }
  &-overlay {
    &-color {
      border-radius: 0.5rem;
      min-width: 3.25rem;
      height: 3.25rem;
      overflow: hidden;
      border: 1.5px solid $col-w;
      background-color: $col-g;
    }
  }
}

.image {
  cursor: pointer;
  &-illust {
    padding-left: 0.25rem;
    @include txt-text;
    word-break: keep-all;
  }
  &-color {
    object-fit: cover;
  }
}

.text {
  &-title {
    @include txt-title-bold;
    color: $col-p-d;
  }
  &-illust {
    padding-left: 0.25rem;
    @include txt-text;
    color: $col-g-f;
    word-break: keep-all;
  }
  &-section {
    width: calc(100% - 1rem);
    padding: 0.5rem;
    @include txt-section-title;
  }
  &-name {
    color: $col-g-f;
    white-space: nowrap;
    padding: 0.25rem;
  }
  &-value {
    // white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 1.5px 0.25rem;
    text-align: right;
    line-height: 1.25;
    word-break: keep-all;
  }
  &-alert {
    @include txt-logo;
    color: $col-g-f;
  }
  &-button-section {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0.25rem;
    padding-left: 1rem;
    font-weight: $font-b;
    color: $col-s-d;
  }
  &-error {
    @include txt-text;
    width: 100%;
    text-align: center;
    word-break: keep-all;
    color: $col-g-f;
    padding: 0.5rem 0;
  }
}
