@import "../util/style.scss";

.wrapper {
  position: relative;
  // @include row(auto, auto, 0.25rem);
  // justify-content: center;
  text-align: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
  // overflow: visible;
  cursor: pointer;
  font-weight: $font-b;
  border-radius: 0.5rem;
  transition: all 0.3s;
  color: $col-s-d;
  width: max-content;
  // width: 100px;
  // box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.05);
  // &:hover {
  //   box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.1);
  // }
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  pointer-events: all;
  &.fillX {
    width: calc(100% - 2rem);
  }
  @media screen and (max-width: $ipad-width) {
    padding: 0.75rem 1.25rem 0.75rem 1.25rem;
    &.fillX {
      width: calc(100% - 2.5rem);
    }
  }
}

.color {
  &-default {
    background-color: $col-w;
    color: $col-dark;
  }
  &-transparent {
    color: $col-grey;
    box-shadow: none;
    font-weight: $font-sr;
    @media (pointer: fine) {
      &:hover {
        background-color: $col-verylight;
        box-shadow: none;
        // @include shd-2;
      }
    }
  }
  &-primary {
    background-color: $col-1;
    color: $col-w;
    // border: 1px solid $col-g9;
    // @include shd-1;
    @media (pointer: fine) {
      &:hover {
        background-color: $col-1-hover;
        // @include shd-2;
      }
    }
  }
  &-nav {
    color: $col-s-d;
    @media (pointer: fine) {
      &:hover {
        background-color: $col-g-l;
      }
    }
    &-emph {
      background-color: $col-s-l;
      color: $col-p-d;
    }
  }
  &-red {
    color: $col-r-f;
    background-color: $col-r-l;
    @media (pointer: fine) {
      &:hover {
        filter: saturate(115%) brightness(97%);
      }
    }
  }
  &-brown-light {
    color: $col-p-d;
    background-color: $col-p-l;
    @media (pointer: fine) {
      &:hover {
        filter: saturate(150%) brightness(97%);
      }
    }
  }
  &-green-light {
    color: $col-s-d;
    background-color: $col-s-l;
    @media (pointer: fine) {
      &:hover {
        filter: saturate(150%) brightness(97%);
      }
    }
  }
}

@media (pointer: fine) {
  .clickable {
    &.transparent {
      cursor: pointer;
      &:hover {
        background-color: $col-g95;
        // @include shd-1;
      }
    }
    &.solid {
      cursor: pointer;
      &:hover {
        background-color: $col-g95;
        // @include shd-2;
      }
    }
  }
}

.full {
  width: 100%;
  height: 100%;
}

.icon {
  fill: $col-w;
  height: 1.1rem;
}
