@import "../util/style.scss";

.wrapper {
  // border: 1.5px solid $col-p-l;

  position: relative;
}

.frame {
  &-btn {
    padding: 3px 8px 3px 12px;
    border-radius: 0.5rem;
    cursor: pointer;
    margin-bottom: 0.375rem;
    @media (pointer: fine) {
      &:hover {
        background-color: $col-g-l;
      }
    }
  }
  &-text {
    @include txt-text-bold;
  }
  &-icon {
    padding: 0.25rem;
    scale: 0.75;
    & * {
      stroke: $col-g-f;
      stroke-width: 2.67px;
    }
  }
  &-dropdown {
    position: absolute;
    right: 0;
    top: 100%;
    border: 1.5px solid $col-g;
    border-radius: 0.5rem;
    transition: all 0.1s;
    min-width: 10rem;
    transform-origin: top right;
    background-color: $col-w;
    z-index: 2;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.1), 0 15px 12px rgba(0, 0, 0, 0.05);
    &.hidden {
      pointer-events: none;
    }
  }
}

.txt {
  &-dropdown {
    @include txt-text-bold;
    padding: 4px 12px;
    border-radius: 0.25rem;
    cursor: pointer;
    &:hover {
      background-color: $col-g-l;
    }
    width: calc(100% - 24px);
    text-align: right;
    &.emph {
      background-color: $col-s-l;
      color: $col-p-d;
    }
  }
}
