@import "../util/reset.css";

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Reem+Kufi:wght@400..700&display=swap");

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 100;
  src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff2)
      format("woff2"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff)
      format("woff"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.otf)
      format("opentype");
  unicode-range: U+AC00-D7A3;
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 300;
  src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff2)
      format("woff2"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff)
      format("woff"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.otf)
      format("opentype");
  unicode-range: U+AC00-D7A3;
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff2)
      format("woff2"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff)
      format("woff"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.otf)
      format("opentype");
  unicode-range: U+AC00-D7A3;
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 500;
  src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff2)
      format("woff2"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff)
      format("woff"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.otf)
      format("opentype");
  unicode-range: U+AC00-D7A3;
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 700;
  src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff2)
      format("woff2"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff)
      format("woff"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.otf)
      format("opentype");
  unicode-range: U+AC00-D7A3;
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 900;
  src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff2)
      format("woff2"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff)
      format("woff"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.otf)
      format("opentype");
  unicode-range: U+AC00-D7A3;
}
@font-face {
  font-family: "Noto Sans KR";
  font-display: swap;
  src: url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap");
  // unicode-range: U+AC00-D7A3;
}

$font-l: 200;
$font-sr: 400;
$font-r: 500;
$font-b: 700;
$font-e: 900;

body {
  // font-family: "Nanum Gothic", sans-serif;
  // font-family: "KoPubDotumMedium";
  // font-family: "NanumSquare";
  margin: 0;
  padding: 0;
  font-family: "Noto Sans KR", "Noto Sans";
  font-weight: $font-r;
  &::-webkit-scrollbar {
    display: none;
  }
}

input {
  font-family: "Noto Sans KR", "Noto Sans";
}

$col-g-f: #969696;
$col-g: #eaeaea;
$col-g-l: #f7f6f6;
$col-k: #000000;
$col-w: #ffffff;

$col-p-d: #55362a;
$col-p-d-deprecated: #523d35;
$col-p-f: #c49f78;
$col-p-f-deprecated: #bba58f;

$col-p-l: #eed9c8;

$col-p-l-deprecated: #e8d9cd;
$col-s-d: #223030;
$col-s-f: #99a890;
$col-s-f-deprecated: #959d90;
$col-s-l: #efefe9;

$col-r: #f96c6c;
$col-r-l: #fde5e5;
$col-r-f: #ce3d3d;

$ipad-width: 767px; // ipad mini width === 768
$min-slide-height: 900px;
$min-card-width: 280px;
$max-card-width: 300px;
$max-card-height: 300px;

@mixin no-drag {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

@mixin txt-logo {
  font-family: "GmarketSans", sans-serif;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.25rem;
}
@mixin txt-logo-eng {
  font-family: "Reem Kufi", sans-serif;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.25rem;
  @media screen and (max-width: $ipad-width) {
    font-size: 1rem;
    line-height: 1;
  }
}
@mixin txt-nav-title {
  font-weight: bold;
  font-size: 1rem;
  line-height: 1rem;
}
@mixin txt-text {
  font-weight: medium;
  font-size: 0.875rem;
  line-height: 1.4;
  @media screen and (max-width: $ipad-width) {
    font-size: 1rem;
    font-weight: 500;
  }
}
@mixin txt-text-bold {
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.4;
  @media screen and (max-width: $ipad-width) {
    font-size: 1rem;
    font-weight: 600;
  }
}
@mixin txt-table-title {
  font-weight: medium;
  font-size: 0.875rem;
  line-height: 1.4;
}
@mixin txt-title {
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.4;
  @media screen and (max-width: $ipad-width) {
    font-size: 1rem;
  }
}
@mixin txt-table-text {
  font-weight: regular;
  font-size: 0.875rem;
  line-height: 1.4;
  @media screen and (max-width: $ipad-width) {
    font-size: 1rem;
  }
}
@mixin txt-table-text-small {
  font-weight: medium;
  font-size: 0.75rem;
  line-height: 1.4;
  @media screen and (max-width: $ipad-width) {
    font-weight: 500;
    font-size: 0.875rem;
  }
}
@mixin txt-title-bold {
  font-weight: 700;
  font-size: 2.75rem;
  line-height: 1.4;
  @media screen and (max-width: $ipad-width) {
    font-size: 2.25rem;
    line-height: 1.4;
  }
}
@mixin txt-my-icon-letter {
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 2rem;
}
@mixin txt-text-illust {
  font-weight: medium;
  font-size: 0.75rem;
  line-height: 0.75rem;
  @media screen and (max-width: $ipad-width) {
    font-size: 0.875rem;
    line-height: 0.875rem;
  }
}
@mixin txt-text-illust-small {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.875rem;
  @media screen and (max-width: $ipad-width) {
    font-size: 0.875rem;
    line-height: 1rem;
  }
}
@mixin txt-card-text {
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.5rem;
}
@mixin txt-section-title {
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1rem;
}
@mixin txt-text-illust-very-small {
  font-weight: medium;
  font-size: 0.5rem;
  line-height: 0.5rem;
}
@mixin txt-text-present {
  font-family: "GmarketSans", sans-serif;
  font-weight: 600;
  font-size: 2.75rem;
  line-height: 3.75rem;
  @media screen and (max-width: $ipad-width) {
    font-size: 2rem;
    line-height: 1.2;
  }
}

$col-dark: #3c3c3c;
$col-light: #e0e0e0;
$col-verylight: #f2f2f2;
$col-verylight-nongrey: #f1f3f4;
$col-grey: #787878;
$col-lightgrey: #a0a0a0;
$col-semilight: #dadce0;

$col-k: #000000;
$col-g1: #1a1a1a;
$col-g2: #333333;
$col-g25: #404040;
$col-g3: #4d4d4d;
$col-g4: #666666;
$col-g5: #808080;
$col-g6: #999999;
$col-g7: #b3b3b3;
$col-g8: #cccccc;
$col-g9: #e6e6e6;
$col-g95: #f2f2f2;
$col-w: #ffffff;
$col-1: #6821ff;
$col-1-hover: #6721ffee;
$col-1-dark: #420cb6fd;
$col-1-transparent: rgba(144, 104, 255, 0.3);
$col-1-dark: #000000;

$col-y: #f9a825;
$col-y-faint: #fff8ee;
// $col-g: #4caf50;
$col-b: #0091ea;
$col-b-transparent: rgba(0, 145, 234, 0.3);

$shd-1: 0 0 5px rgba(0, 0, 0, 0.25);
$shd-w: 0 0 2px rgba(255, 255, 255, 1);

$shd-border-w: -1px 0 2px #fff, 0 1px 2px #fff, 1px 0 2px #fff, 0 -1px 2px #fff;

@mixin shd-1 {
  box-shadow: 0 0 0.25rem $col-shd-b;
}

@mixin shd-2 {
  box-shadow: 0 0 1rem $col-shd-b;
}

$col-txt-b: #000000;
$col-txt-g1: #1a1a1a;
$col-txt-g2: #333333;
$col-txt-g25: #404040;
$col-txt-g3: #4d4d4d;
$col-txt-g4: #666666;
$col-txt-g5: #808080;
$col-txt-g6: #999999;
$col-txt-g7: #b3b3b3;
$col-txt-g8: #cccccc;
$col-txt-g9: #e6e6e6;
$col-txt-g95: #f2f2f2;
$col-txt-w: #ffffff;
$col-txt-1: #b74bdd;
$col-txt-2: #7c4dff;
$col-txt-grey: #787878;

$col-bgd-b: #000000;
$col-bgd-g1: #1a1a1a;
$col-bgd-g2: #333333;
$col-bgd-g25: #404040;
$col-bgd-g3: #4d4d4d;
$col-bgd-g4: #666666;
$col-bgd-g5: #808080;
$col-bgd-g6: #999999;
$col-bgd-g7: #b3b3b3;
$col-bgd-g8: #cccccc;
$col-bgd-g9: #e6e6e6;
$col-bgd-g95: #f2f2f2;
$col-bgd-w: #ffffff;
$col-bgd-1: #b74bdd;
$col-bgd-1-hover: #a726a2;
$col-bgd-1-pressed: #850180;
$col-bgd-2: #7c4dff;
$col-bgd-3: #f0ebff;
$col-bgd-4: #c6b3ff;

$col-brd-b: #000000;
$col-brd-g1: #1a1a1a;
$col-brd-g2: #333333;
$col-brd-g25: #404040;
$col-brd-g3: #4d4d4d;
$col-brd-g4: #666666;
$col-brd-g5: #808080;
$col-brd-g6: #999999;
$col-brd-g7: #b3b3b3;
$col-brd-g8: #cccccc;
$col-brd-g9: #e6e6e6;
$col-brd-g95: #f2f2f2;
$col-brd-w: #ffffff;
$col-brd-2: #7c4dff;

$col-shd-b: rgba(0, 0, 0, 0.25);
$eff-shd-1: 4px 6px 22px rgba(0, 0, 0, 0.03);
$col-shd-w: #ffffff;

$font-xs: 0.75rem;
$font-sm: 0.875rem;
$font-base: 1rem;
$font-lg: 1.125rem;
$font-xl: 1.25rem;

@mixin column($w, $h, $gap) {
  display: flex;
  flex-direction: column;
  gap: $gap;
  align-items: center;
  box-sizing: content-box;
  // box-sizing: border-box;
  > * {
    width: $w;
    height: $h;
    // text-align: center;
  }
}

@mixin row($w, $h, $gap) {
  display: flex;
  flex-direction: row;
  gap: $gap;
  align-items: center;
  box-sizing: content-box;
  // box-sizing: border-box;
  > * {
    width: $w;
    height: $h;
  }
}

@mixin box($w, $h, $r, $col-border, $col-fill) {
  box-sizing: border-box;
  color: $col-txt-b;
  width: $w;
  height: $h;
  background: $col-fill;
  border: solid $col-border 1px;
  border-radius: $r;
}

@mixin textBox($w, $h, $r, $col-border, $col-fill) {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  color: $col-txt-b;
  width: $w;
  height: $h;
  background: $col-fill;
  border: solid $col-border 1px;
  border-radius: $r;
}

@mixin columnWithBorder($w, $h, $gap, $col-border) {
  @include column($w, $h, 0px);
  > * {
    border-top: 1px solid $col-border;
    padding-top: ($gap / 2);
    padding-bottom: ($gap / 2);
  }
  > :first-child {
    border-top: none;
    padding-top: 0px;
  }
}

.map {
  height: auto;
}
