@import "../util/style.scss";

.wrapper {
  padding: 0.25rem;
  cursor: pointer;
}

.frame {
  &-icon {
    * {
      fill: #ffffff00;
      stroke: $col-p-f;
      transition: all 0.1s;
    }
    &.like {
      & * {
        fill: $col-r;
        stroke: $col-r;
      }
      // &.hover * {
      //   fill: #ffffff00;
      //   stroke: $col-p-f;
      // }
    }
  }
}

.text {
  &-like {
    min-width: 1.5rem;
    text-align: center;
    @include txt-table-text-small;
    color: $col-s-d;
  }
}
