@import "../util/style.scss";

.wrapper {
  @include txt-table-text-small;
  max-width: calc(min(100%, 50vw));
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: $col-s-d;
  border-radius: 16px;
  white-space: nowrap;
  // transition: background-color 0.1s;

  @media (pointer: fine) {
    transition: background-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  // border: 1.5px solid $col-p-l;
  box-shadow: 0 0 0 1px $col-p-l inset;
  vertical-align: middle;
  box-sizing: border-box;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: hidden;
}

.frame {
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 2px 8px;
  @media screen and (max-width: $ipad-width) {
    padding: 4px 12px;
  }
  white-space: nowrap;
}

.clickable.unclicked {
  cursor: pointer;
  @media (pointer: fine) {
    &:hover {
      background-color: $col-g-l;
    }
  }
}

.clickable.clicked {
  cursor: pointer;
  background-color: $col-p-l;
  // &:hover {
  //   // background-color: $col-w;
  // }
}

.disable.clicked {
  background-color: $col-g;
  box-shadow: 0 0 0 1px $col-g inset;
}

.fixed {
  overflow: visible;
  text-overflow: initial;
}
