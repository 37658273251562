@import "../util/style.scss";

.wrapper {
  height: calc(var(--new100vh) - 4rem);
  padding: 2rem 1rem;
}

.body {
  max-width: 1024px;
  width: 100%;
}

.frame {
  &-title {
    padding-top: 4rem;
    width: 100%;
  }
  &-section {
    padding: 2.5rem 0.25rem 0.5rem 0.25rem;
    width: calc(100% - 0.5rem);
    @include txt-section-title;
    text-align: left;
  }
  &-space {
    height: 2rem;
  }
  &-popup {
    width: 640px;
    max-width: calc(var(--100vw) - 4rem);
    word-break: keep-all;
  }
}

.text {
  &-title {
    @include txt-title-bold;
    color: $col-p-d;
  }
  &-illust {
    padding-left: 0.25rem;
    @include txt-text;
    color: $col-g-f;
  }
  &-popup {
    &-title {
      @include txt-section-title;
      line-height: 1.5;
      color: $col-k;
      text-align: center;
    }
    @include txt-nav-title;
    line-height: 1.5;
    font-weight: 500;
    color: $col-p-f;
    word-break: keep-all;
    text-align: center;
  }
}
