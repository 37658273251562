@import "../util/style.scss";

.wrapper {
  padding: 0rem;
}

.frame {
  &-no {
    @include textBox(1.5rem, 1.5rem, 0.5rem, none, none);
    cursor: pointer;
    @include txt-text;
    color: $col-p-d;
    &:hover {
      background-color: $col-g-l;
    }
    &.emph {
      background-color: $col-p-l;
    }
  }
}

.text {
  &-like {
    min-width: 1.5rem;
    text-align: center;
    @include txt-table-text-small;
    color: $col-s-d;
  }
}
