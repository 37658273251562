@import "../util/style.scss";

.wrapper {
  padding: 1rem 0;
  width: 100%;
}

.frame {
  &-subfilter {
    background-color: $col-g-l;
    padding: 0.375rem;
    border-radius: 1rem;
  }
  &-div {
    width: 100%;
  }
  &-popup {
    width: 640px;
    max-width: calc(var(--100vw) - 4rem);
    &-textarea {
      background-color: $col-g-l;
      padding: 0.75rem 1rem;
      border: $col-g 1.5px solid;
      border-radius: 0.5rem;
      width: calc(100% - 2rem);
      @include txt-nav-title;
      font-family: "Noto Sans KR";
      line-height: 1.5;
      font-weight: 500;
      color: $col-k;
      resize: none;
      &::placeholder {
        color: $col-g-f;
      }
    }
  }
}

.text {
  &-subsection {
    @include txt-table-text-small;
    color: $col-g-f;
  }
  &-popup {
    &-title {
      @include txt-section-title;
      line-height: 1.5;
      color: $col-k;
      width: 100%;
      text-align: center;
    }
    @include txt-nav-title;
    line-height: 1.5;
    font-weight: 500;
    color: $col-p-f;
    width: 100%;
    text-align: center;
    &.left {
      text-align: left;
    }
  }
}
