@import "../util/style.scss";

.frame {
  &-qdiv {
    width: calc(25% - 0.75rem);
    // min-width: 200px;
    height: 100%;
  }
  &-qqdiv {
    width: 100%;
    height: calc(25% - 0.75rem);
    // min-width: 200px;
  }
}

@media screen and (max-width: $ipad-width) {
  .frame {
    &-qdiv {
      min-width: 200px;
      margin-bottom: 5rem;
    }
    &-qqdiv {
      min-width: 200px;
    }
  }
}
