@import "../util/style.scss";

.wrapper {
  position: relative;
  overflow-x: scroll;
  border-radius: 0.5rem;
  max-width: 100%;
  pointer-events: all;
  &.green-light {
    background-color: $col-s-l;
  }
  &::-webkit-scrollbar {
    height: 0;
    width: 0;
  }
}

.frame {
  &-button {
    // @include row(auto, auto, 0.25rem);
    // justify-content: center;
    text-align: center;
    cursor: pointer;
    padding: 0.25rem 1rem 0.25rem 1rem;

    font-weight: $font-b;
    border-radius: 0.375rem;
    @media screen and (min-width: $ipad-width) {
      transition: all 0.3s;
    }
    // width: 40px;
    word-break: keep-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media screen and (max-width: $ipad-width) {
      padding: 0.5rem 1.25rem 0.5rem 1.25rem;
    }
  }
}

.color {
  &-brown-light {
    color: $col-p-d;
    background-color: $col-p-l;
    @media (pointer: fine) {
      &:hover {
        filter: brightness(95%);
      }
    }
    &.emph {
      background-color: $col-p-f;
      color: $col-w;
      cursor: default;
      @media (pointer: fine) {
        &:hover {
          filter: brightness(100%);
        }
      }
    }
  }
  &-green-light {
    color: $col-s-d;
    background-color: $col-s-l;
    @media (pointer: fine) {
      &:hover {
        filter: brightness(95%);
      }
    }
    &.emph {
      background-color: $col-s-f;
      color: $col-w;
      cursor: default;
      @media (pointer: fine) {
        &:hover {
          filter: brightness(100%);
        }
      }
    }
  }
}
@media (pointer: fine) {
  .clickable {
    &.transparent {
      cursor: pointer;
      &:hover {
        background-color: $col-g95;
        // @include shd-1;
      }
    }
    &.solid {
      cursor: pointer;
      &:hover {
        background-color: $col-g95;
        // @include shd-2;
      }
    }
  }
}

.full {
  width: 100%;
  height: 100%;
}

.icon {
  fill: $col-w;
  height: 1.1rem;
}
