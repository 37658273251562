@import "../util/style.scss";

.wrapper {
  cursor: pointer;

  width: 100%;
  height: 100%;
  border-radius: 2rem;
  transition: all 0.3s;
  background-color: $col-g-l;
  overflow: hidden;
  &:hover {
    background-color: $col-s-l;
  }
}

.frame {
  &-text {
    padding: 0.25rem 0;
  }
  &-chip {
    padding: 0 0.25rem;
  }
  &-icon {
    width: 105px;
    height: 42px;
    scale: 0.25;
    margin-top: 5rem;
    &.small {
      margin-top: 0rem;
    }
  }
}

.image {
  object-fit: cover;
  margin: 0;
  padding: 0;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.text {
  &-title {
    @include txt-card-text;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: keep-all;
    color: $col-s-d;
  }
  &-illust {
    @include txt-card-text;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $col-g-f;
    word-break: keep-all;
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    background-color: $col-g-l;
    &:hover {
      background-color: $col-g-l;
    }
  }

  .text {
    &-title {
      @include txt-card-text;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: keep-all;
      color: $col-s-d;
    }
    &-illust {
      @include txt-text;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $col-g-f;
      word-break: keep-all;
    }
  }
}
