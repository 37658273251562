@import "../util/style.scss";

.wrapper {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  &.x-scroll {
    width: 100vw;
    overflow-x: scroll;
    justify-content: flex-start;
  }
}

.frame {
  width: 100%;
  &.full {
    // position: absolute;
    min-width: 100vw;
  }
  &-space {
    width: calc(50vw - 512px);
    min-width: 1rem;
    height: 100%;
  }
}

.text {
  &-alert {
    @include txt-logo;
    color: $col-g-f;
    padding: 6rem 0 4rem 0;
    text-align: center;
    word-break: keep-all;
    line-height: 1.5;
  }
}
