@import "../util/style.scss";

.wrapper {
  @media screen and (min-width: $ipad-width) {
    height: calc(var(--new100vh) - 4rem);
  }
  @media screen and (max-width: $ipad-width) {
    &.like {
      height: calc(var(--new100vh) - 4rem);
      min-height: $min-slide-height;
    }
  }
  padding: 2rem 1rem 1rem 1rem;
  width: calc(100vw - 2rem);
  overflow-x: hidden;
}

.background {
  width: 100%;
  height: 100%;
  filter: blur(20px);
}

.body {
  max-width: 1024px;
  width: 100%;
  height: 100%;
  &.scrollable {
    min-height: 600px;
  }
  // overflow: hidden;
}

.frame {
  &-title {
    padding-top: 4rem;
    width: 100%;
  }
  &-content {
    width: 100%;
    padding: 1rem 0 0 0;
    @media screen and (min-width: $ipad-width) {
      height: calc(100% - 1rem);

      max-height: 720px;
      min-height: 0;
    }
    // flex-grow: 1;
    flex-shrink: 1;
  }
  &-image {
    position: relative;
    width: 75%;
    height: 100%;
    background-color: $col-g-l;
    border: 1.5px solid $col-g;
    border-radius: 1rem;
    position: relative;
    overflow: hidden;
    @media screen and (max-width: $ipad-width) {
      min-width: calc(100vw - 2rem);
      height: calc(min(calc(100vw - 2rem), 60vh));
    }
    &-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      pointer-events: none;
      z-index: 100;
    }
  }
  &-info {
    width: 25%;
    min-width: 300px;
    @media screen and (min-width: $ipad-width) {
      height: 100%;
    }
    @media screen and (max-width: $ipad-width) {
      width: calc(100vw - 2rem);
      min-width: calc(100vw - 2rem);
      & > * > :nth-child(1) {
        height: 60vh;
      }
      padding-bottom: 2rem;
    }
  }
  &-div {
    width: 100%;

    height: calc(50% - 0.5rem - 1.5px);

    background-color: $col-g-l;
    border: 1.5px solid $col-g;
    border-radius: 1rem;
  }
  &-scroll {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 6px;

      &-thumb {
        background: $col-g;
        border-radius: 1rem;
        &:hover {
          background: $col-g-f;
        }
      }
    }
  }
  &-loading {
    background: $col-g;
  }
  &-content2 {
    width: 100vw;

    height: 100%;

    // flex-grow: 1;
    flex-shrink: 1;
    overflow-x: auto;
    overflow-y: visible !important;

    &::-webkit-scrollbar {
      height: 6px;
      width: 0;
      &-thumb {
        background: $col-g;
        border-radius: 1rem;
        &:hover {
          background: $col-g-f;
        }
      }
    }
  }
  &-margin2 {
    width: calc(calc(100vw - 1056px) / 2);
    min-width: 1px;
    height: 100%;
  }
  &-div2 {
    width: calc(min(calc(max(33.33%, $min-card-width)), 314.67px));
    flex-shrink: 0;
    max-height: calc(min(720px, calc(100% - 2rem)));
    display: flex;
    background-color: $col-g-l;
    border: 1.5px solid $col-g;
    border-radius: 1rem;
    padding: 0.5rem 0.35rem 0.5rem 0.5rem;
  }
  &-scroll2 {
    width: 100%;
    min-height: 0;
    flex-grow: 1;
    flex-shrink: 1;
    max-height: 100%;
    overflow-y: auto;
    padding-right: 0.2rem;
    &::-webkit-scrollbar {
      width: 6px;

      &-thumb {
        background: $col-g;
        border-radius: 1rem;
        &:hover {
          background: $col-g-f;
        }
      }
    }
  }
  &-icon {
    cursor: pointer;
    transition: all 0.2s;
    transform: rotate(-180deg);
    & * {
      stroke: $col-g-f;
    }
    &.close {
      transform: rotate(0deg);
    }
  }
  &-subsection {
    width: calc(100% - 1rem);
    padding: 0.25rem 0.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all 0.3s;
    @media (pointer: fine) {
      &:hover {
        background-color: $col-s-l;
      }
    }
  }
  &-dropdown {
    overflow: hidden;
    width: 100%;
  }
}

.text {
  &-title {
    @include txt-title-bold;
    color: $col-p-d;
  }
  &-illust {
    padding-left: 0.25rem;
    @include txt-text;
    color: $col-g-f;
    word-break: keep-all;
  }
  &-section {
    width: calc(100% - 1rem);
    padding: 0.5rem;
    @include txt-section-title;
  }
  &-subsection {
    color: $col-g-f;
  }
  &-name {
    color: $col-g-f;
  }
  &-alert {
    @include txt-logo;
    color: $col-g-f;
    word-break: keep-all;
    &.small {
      font-size: 1rem;
      opacity: 0.5;
      padding: 1rem;
      text-align: center;
    }
  }
  &-error {
    @include txt-text;
    width: 100%;
    text-align: center;
    word-break: keep-all;
    color: $col-g-f;
    padding: 0.5rem 0;
  }
}
