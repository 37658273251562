@import "../util/style.scss";

.wrapper {
  @media (pointer: fine) {
    scroll-snap-type: y mandatory;
  }
  height: var(--new100vh);
  width: 100%;
  overflow-y: scroll;
}

.frame {
  &-first {
    position: relative;
    width: calc(100% - 2rem);
    padding: 0 1rem;
    scroll-snap-align: start;
  }
  &-top {
    width: 100%;
    max-width: 1024px;
    padding-top: 4rem;
    padding-bottom: 1rem;
  }
  &-bottom {
    min-height: calc(calc(0.5 * var(--new100vh)) - 2rem);
    width: 100%;
    max-width: 1024px;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  &-full {
    position: relative;
    height: var(--new100vh);
    width: 100%;
    @include txt-text-present;
    &.first {
      background-color: $col-g;
      scroll-snap-align: center;
    }
    &.second {
      background-color: $col-p-f;
      scroll-snap-align: start;
    }
    &-overlay {
      position: absolute;
      top: 0;
      left: 0;
      height: var(--100vh);
      width: 100%;
      overflow: hidden;
    }
  }
  &-overlay {
    position: relative;
    height: 0;
    bottom: 6rem;
    cursor: pointer;
  }
  &-footer {
    position: absolute;
    bottom: 0;
    left: 0rem;
    padding: 0.5rem 1rem;
    width: calc(100% - 2rem);
    background-color: $col-g-l;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
}

.text {
  &-logo {
    @include txt-logo-eng;
    color: $col-p-f;
  }
  &-placeholder {
    @include txt-logo-eng;
    color: $col-p-d;
    padding: 0.125rem 0;
    border: 1.5px solid $col-p-f;
    width: 140px;
    text-align: center;
    border-radius: 0.25rem;
  }
  &-title {
    @include txt-title-bold;
    background: linear-gradient(0.25turn, #55362a, #b88977);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    word-break: keep-all;
  }
  &-overlay {
    @include txt-text;
    color: $col-g-f;
  }

  &-present {
    opacity: 0.5;
    color: $col-w;
    text-align: center;
    word-break: keep-all;
    filter: drop-shadow(0 5px 10px rgba(0, 0, 0, 0.15));
    &.white {
      filter: drop-shadow(0 0px 10px rgba(255, 255, 255, 0.25));
    }
  }
  &-present-2 {
    opacity: 0.5;
    color: $col-p-l;
    text-align: center;
    word-break: keep-all;
  }
  &-present-3 {
    @include txt-text-present;
    &.g-f {
      color: $col-g-f;
    }
    &.p-f {
      color: $col-p-d;
    }
  }
}

.image {
  // width: calc(min(20vw, calc(0.2 * var(--100vh))));
  // height: calc(min(20vw, calc(0.2 * var(--100vh))));
  // margin: calc(min(2vw, calc(0.02 * var(--100vh))));
  // border-radius: calc(min(3vw, calc(0.03 * var(--100vh))));
  width: calc(0.2 * var(--100vh));
  height: calc(0.2 * var(--100vh));
  margin: calc(0.02 * var(--100vh));
  border-radius: calc(0.03 * var(--100vh));
  box-shadow: 0 19px 50px rgba(0, 0, 0, 0.2), 0 15px 30px rgba(0, 0, 0, 0.1);
  opacity: 0.5;
  object-fit: cover;
  &.absolute {
    position: absolute;
    margin: 0;
    top: calc(0.5 * var(--100vh));
    left: 50%;
  }
}

@media screen and (max-width: $ipad-width) {
  .text {
    &-logo {
    }
    &-placeholder {
      border: 1.5px solid $col-p-f;
      width: 105px;
    }
    &-title {
      // padding: 0 1rem 0 1rem;
      // text-align: left;
    }
    &-overlay {
    }

    &-present {
      padding: 0 1rem 0 1rem;
    }
    &-present-2 {
      padding: 0 1rem 0 1rem;
    }
    &-present-3 {
    }
  }
}
