@import "../util/style.scss";

.wrapper {
  @media screen and (min-width: $ipad-width) {
    height: calc(var(--new100vh) - 3rem);
  }
  padding: 2rem 1rem 1rem 1rem;
}

.background {
  width: 100%;
  height: 100%;
  filter: blur(20px);
}

.body {
  max-width: 1024px;
  width: 100%;
  height: 100%;
  &.scrollable {
    min-height: 600px;
  }
  // overflow: hidden;
}

.frame {
  &-title {
    padding-top: 4rem;
    width: 100%;
  }
  &-content {
    width: 100%;
    // padding: 1rem 0 0 0;
    height: calc(100% - 1rem);

    max-height: 720px;
    min-height: 0;
    // flex-grow: 1;
    flex-shrink: 1;
  }
  &-image {
    width: 75%;
    height: 100%;
    @media screen and (max-width: $ipad-width) {
      min-width: calc(100vw - 2rem);
      height: calc(min(calc(100vw - 2rem), 60vh));
    }
    background-color: $col-g-l;
    border: 1.5px solid $col-g;
    border-radius: 1rem;
    position: relative;
    overflow: hidden;
    &-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      pointer-events: none;
      z-index: 100;
    }
  }
  &-info {
    width: 25%;
    min-width: 300px;
    @media screen and (min-width: $ipad-width) {
      height: 100%;
    }
    @media screen and (max-width: $ipad-width) {
      width: calc(100vw - 2rem);
      min-width: calc(100vw - 2rem);
      height: 60vh;
      padding-bottom: 2rem;
    }
  }
  &-div {
    width: 100%;

    height: 100%;

    background-color: $col-g-l;
    border: 1.5px solid $col-g;
    border-radius: 1rem;
  }
  &-scroll {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 6px;

      &-thumb {
        background: $col-g;
        border-radius: 1rem;
        &:hover {
          background: $col-g-f;
        }
      }
    }
  }
  &-icon {
    cursor: pointer;
    transition: all 0.2s;
    transform: rotate(-180deg);
    & * {
      stroke: $col-g-f;
    }
    &.close {
      transform: rotate(0deg);
    }
  }
  &-subsection {
    width: calc(100% - 1rem);
    padding: 0.25rem 0.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all 0.3s;
    @media (pointer: fine) {
      &:hover {
        background-color: $col-s-l;
      }
    }
  }
  &-dropdown {
    overflow: hidden;
    width: 100%;
  }
  &-delete {
    border-radius: 2rem;
    min-width: 1rem;
    height: 1rem;
    padding: 0.25rem;
    transition: all 0.15s;
    cursor: pointer;
    @media (pointer: fine) {
      &:hover {
        background-color: $col-g;
      }
    }
    & svg {
      width: 1rem;
      height: 1rem;
      // stroke: $col-g-f;
      fill: $col-g-f;
    }
  }
}

.text {
  &-title {
    @include txt-title-bold;
    color: $col-p-d;
  }
  &-illust {
    padding-left: 0.25rem;
    @include txt-text;
    color: $col-g-f;
    word-break: keep-all;
  }
  &-section {
    width: calc(100% - 1rem);
    padding: 0.5rem;
    @include txt-section-title;
    &.tight {
      width: auto;
    }
    &.amount {
      padding-left: 0;
      color: $col-p-f;
    }
  }
  &-amount {
    @include txt-title;
    color: $col-p-d;
    &.input {
      text-align: right;
      width: 72px;
      border-radius: 0.375rem;
      transition: all 0.3s;
      &::placeholder {
        color: $col-p-f;
      }
      @media (pointer: fine) {
        &:hover,
        &:focus {
          background-color: $col-g;
        }
      }
    }
  }
  &-subsection {
    color: $col-g-f;
  }
  &-name {
    color: $col-g-f;
  }
  &-alert {
    @include txt-logo;
    color: $col-g-f;
  }
  &-error {
    @include txt-text;
    width: 100%;
    text-align: center;
    word-break: keep-all;
    color: $col-g-f;
    padding: 0.5rem 0;
  }
}
