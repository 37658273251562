@import "../util/style.scss";

.wrapper {
  // border: 1.5px solid $col-p-l;

  position: relative;
  pointer-events: all;
}

.frame {
  &-btn {
    padding: 0px 8px 0px 12px;
    border-radius: 0.5rem;
    cursor: pointer;
    margin-bottom: 0.375rem;
    &:focus-within,
    &:hover {
      background-color: $col-g-l;
    }
    &.white {
      background-color: $col-w;
      border: 1.5px solid $col-g;
      // box-shadow: 0 19px 38px rgba(0, 0, 0, 0.1);
      // 0 15px 12px rgba(0, 0, 0, 0.05);
    }
  }
  &-text {
    @include txt-text-bold;
    width: 7.5rem;
    &::placeholder {
      color: $col-g-f;
    }
  }
  &-icon {
    padding: 4px 0px 2px 4px;

    & * {
      stroke: $col-g-f;
    }
    &:hover * {
      stroke: $col-p-f;
    }
  }
  &-dropdown {
    position: absolute;
    right: 0;
    top: 100%;
    padding: 0.25rem;
    border: 1.5px solid $col-g;
    border-radius: 0.5rem;
    transition: all 0.1s;
    min-width: 10rem;
    max-width: 150%;
    transform-origin: top right;
    background-color: $col-w;
    z-index: 2;

    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.1), 0 15px 12px rgba(0, 0, 0, 0.05);
    &-inside {
      overflow-y: auto;
      overflow-x: hidden;
      width: 100%;
      max-height: calc(min(calc(0.2 * var(--100vh)), 15rem));
      &::-webkit-scrollbar {
        width: 6px;

        &-thumb {
          background: $col-g;
          border-radius: 1rem;
          &:hover {
            background: $col-g-f;
          }
        }
      }
    }
    &.hidden {
      pointer-events: none;
    }
  }
}

.txt {
  &-subsection {
    @include txt-table-text-small;
    padding: 4px 12px 0px 0px;
    color: $col-g-f;
    width: 100%;
    text-align: right;
  }
  &-dropdown {
    @include txt-text-bold;
    padding: 4px 12px;
    border-radius: 0.25rem;
    cursor: pointer;
    &:hover {
      background-color: $col-g-l;
    }
    width: calc(100% - 24px);
    text-align: right;
    white-space: nowrap;
    &.emph {
      background-color: $col-s-l;
      color: $col-p-d;
    }
    &-illust {
      @include txt-text-illust-small;
      color: $col-g-f;
      text-align: right;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
