@import "../util/style.scss";

.wrapper {
  width: calc(100% - 4px);
  max-width: calc(100% - 4px);
  padding-right: 0.25rem;
  border-radius: 0.75rem;
  transition: all 0.3s, width 0s, gap 0s;
  min-width: 0;
  @media screen and (pointer: fine) {
    cursor: pointer;
    &:hover {
      background-color: $col-s-l;
    }
  }
}

.frame {
  &-text {
    padding: 0.25rem 0;
    flex-shrink: 1;
    flex-grow: 1;
    min-width: 0;
  }
  &-chip {
    padding: 0 0.25rem;
  }
  &-content {
    width: 100%;

    height: 100%;

    // flex-grow: 1;
    flex-shrink: 0;
    overflow-x: auto;
    overflow-y: visible !important;

    &::-webkit-scrollbar {
      height: 0px;

      &-thumb {
        background: $col-g;
        border-radius: 1rem;
        &:hover {
          background: $col-g-f;
        }
      }
    }
  }
  &-image {
    margin: 0.25rem;
    min-width: 48px;
    height: 48px;
    border-radius: 0.5rem;
    overflow: hidden;
    flex-grow: 0;
    // background-size: cover;
    // background-position: center;
    background-color: $col-g-l;
    &.image {
      border-radius: 0rem;
      background-color: #ffffffff;
    }
    &.small {
      min-width: 24px;
      height: 24px;
      border-radius: 1rem;
    }
  }
  &-hover {
    // position: absolute;
    background-color: $col-w;
    width: 100%;
    height: 100%;
  }

  &-color {
    border-radius: 3rem;
    width: calc(1.25rem - 1.5px);
    height: calc(1.25rem - 1.5px);
    overflow: hidden;
    border: 1.5px solid $col-g;
    background-color: $col-w;
  }
  &-loading {
    margin: 0.25rem;
    width: 3rem;
    height: 3rem;
    border-radius: 0.5rem;
    background-color: $col-g;
  }
}

.image {
  object-fit: cover;
  margin: 0;
  padding: 0;
  &.hover {
    display: none;
  }
  &-color {
    object-fit: cover;
  }
}

.text {
  &-value {
    @include txt-title;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: $col-s-d;
    width: 100%;
    min-height: 1.25rem;
    &.center {
      width: auto;
    }
    &.loading {
      background-color: $col-g-l;
      margin-top: 0.25rem;
      border-radius: 0.25rem;
      height: 1rem;
    }
  }
  &-name {
    @include txt-table-text-small;
    color: $col-g-f;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &.center {
      width: auto;
    }
    &.loading {
      background-color: $col-g-l;
      margin-bottom: 0.25rem;
      border-radius: 0.25rem;
      height: 0.75rem;
    }
  }
}
