@import "../util/style.scss";

.wrapper {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $col-p-l;
  width: 100vw;
  height: var(--100vh);
  overflow: hidden;
}

.iframe {
  width: 100vw;
  max-width: 545px;
  min-height: 545px;
  max-height: 1024px;
  height: var(--100vh);
  border: none;
  overflow: hidden;
  box-shadow: 0 0 3rem rgba(0, 0, 0, 0.35);
}

.overlay {
  &-wrapper {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }

  pointer-events: none;
  justify-content: end;
  padding: 1rem;
  width: calc(100vw - 2rem);
  max-width: calc(545px - 2rem);
  min-height: calc(545px - 2rem);
  max-height: calc(1024px - 2rem);
  height: calc(var(--100vh) - 2rem);
  border: none;
}

.frame-icon {
  position: relative;
  height: 1rem;
  width: 1rem;
  // overflow: hidden;
}

.icon {
  position: absolute;
  top: -0.3rem;
  left: -0.125rem;
  // overflow: hidden;
}
