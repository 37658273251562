@import "../util/style.scss";

.wrapper {
  cursor: pointer;
  padding: 0.25rem;
  border-radius: 0.75rem;
  transition: all 0.3s, width 0s;
  @media screen and (pointer: fine) {
    &:hover {
      background-color: $col-s-l;
    }
  }
  &.image {
    padding: 0.0625rem;
    border-radius: 0rem;
    background-color: #ffffffff;
  }
}

.frame {
  &-text {
    padding: 0.25rem 0;
    width: calc(100% - 32px);
  }
  &-chip {
    padding-left: 0.25rem;
    width: calc(100% - 0.25rem);
    &.loading {
      background-color: $col-g-l;
      border-radius: 0.25rem;
      height: 1.625rem;
      margin-top: 0.25rem;
      margin-bottom: 0.25rem;
    }
  }
  &-image {
    width: 100%;
    position: relative;
    border-radius: 0.5rem;
    overflow: hidden;
    // background-size: cover;
    // background-position: center;
    background-color: $col-g-l;
    &.image {
      border-radius: 0rem;
      background-color: #ffffffff;
    }
  }
  &-hover {
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(rgba(255, 255, 255, 0.5), $col-w 75%);
    width: 100%;
    height: 100%;
  }
  &-loading {
    width: 100%;
    height: 100%;
    background-color: $col-g;
  }
  &-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    &-color {
      border-radius: 3rem;
      min-width: 1.5rem;
      height: 1.5rem;
      overflow: hidden;
      border: 1.5px solid $col-w;
      background-color: $col-g;
    }
    &-have {
      @include txt-table-text-small;
      background-color: $col-k;

      width: 1.25rem;
      height: 1.25rem;
      border-radius: 0.25rem;
      opacity: 0.35;
      color: $col-w;
    }
    &-type {
      @include txt-table-text-small;
      background-color: $col-k;
      height: 1.25rem;
      border-radius: 0.25rem;
      opacity: 0.35;
      padding: 0 0.25rem;
      color: $col-w;
    }
  }
}

.x-scroll {
  margin: 0.5rem;
  max-width: calc(100% - 1rem);
}

.image {
  object-fit: cover;
  margin: 0;
  padding: 0;
  // &.hover {
  //   display: none;
  //   opacity: 0.5;
  // }
  &-color {
    object-fit: cover;
  }
}

.text {
  &-title {
    padding-left: 0.5rem;
    @include txt-title;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $col-s-d;
    width: calc(100% - 0.5rem);
    &.loading {
      background-color: $col-g-l;
      margin-top: 0.25rem;
      border-radius: 0.25rem;
      height: 1.25rem;
    }
  }
  &-illust {
    padding-left: 0.25rem;

    @include txt-text;
    color: $col-g-f;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2; // 원하는 라인수
    -webkit-box-orient: vertical;
  }
}
