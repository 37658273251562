@import "../util/style.scss";

.wrapper {
  height: calc(var(--new100vh) - 3rem);
  padding: 2rem 1rem 1rem 1rem;
  min-height: $min-slide-height;
}

.body {
  max-width: 1024px;
  width: 100%;
  height: 100%;
  // min-height: 600px;
}

.frame {
  &-title {
    padding-top: 4rem;
    width: 100%;
  }
  &-content {
    width: 100vw;

    height: 100%;

    // flex-grow: 1;
    flex-shrink: 1;
    overflow-x: auto;
    overflow-y: visible !important;

    &::-webkit-scrollbar {
      height: 6px;
      width: 0;
      &-thumb {
        background: $col-g;
        border-radius: 1rem;
        &:hover {
          background: $col-g-f;
        }
      }
    }
  }
  &-margin {
    width: calc(calc(100vw - 1056px) / 2);
    min-width: 1px;
    height: 100%;
  }
  &-div {
    width: calc(min(calc(max(30%, $min-card-width)), $max-card-width));
    // width: 300px;
    max-height: calc(min(720px, calc(100% - 2rem)));
    display: flex;
    background-color: $col-g-l;
    border: 1.5px solid $col-g;
    border-radius: 1rem;
    padding: 0.5rem 0.35rem 0.5rem 0.5rem;
  }
  &-scroll {
    width: 100%;
    min-height: 0;
    flex-grow: 1;
    flex-shrink: 1;
    max-height: 100%;
    overflow-y: auto;
    padding-right: 0.2rem;
    &::-webkit-scrollbar {
      width: 6px;

      &-thumb {
        background: $col-g;
        border-radius: 1rem;
        &:hover {
          background: $col-g-f;
        }
      }
    }
  }
}

.text {
  &-title {
    @include txt-title-bold;
    color: $col-p-d;
  }
  &-illust {
    padding-left: 0.25rem;
    @include txt-text;
    color: $col-g-f;
  }
  &-section {
    width: calc(100% - 1rem);
    padding: 0.5rem;
    @include txt-section-title;
  }
  &-error {
    @include txt-text;
    width: 100%;
    text-align: center;
    word-break: keep-all;
    color: $col-g-f;
    padding: 0.5rem 0;
  }
}
