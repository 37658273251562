@import "../util/style.scss";

.wrapper {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  transition: all 0.3s;
  height: 3rem;
  &.close {
    top: -3.1rem;
  }
}

.frame {
  &-fixed {
    height: 2.25rem;
    width: calc(100vw - 1rem);
    padding: 0.375rem 0.5rem 0.375rem 0.5rem;
    background-color: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(4px);
    overflow: hidden;
  }
  &-menu {
    // width: 100%;
    flex-grow: 1;
    padding-right: 0.5rem;
    display: flex;
    justify-content: end;
    & > * {
      fill: $col-g-f;
      cursor: pointer;
    }
    transform: opacity 0.3s;
  }
  &-header {
    background-color: $col-w;
    border-bottom: 1.5px solid $col-p-l;
    padding: 0 1rem 0 1rem;
    width: calc(100% - 2rem);
    height: 100%;
  }
  &-logo {
    @include txt-logo;
    color: $col-p-f;
    padding: 0.625rem 0.5rem 0.375rem 0.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
    @media (pointer: fine) {
      &:hover {
        background-color: $col-g-l;
      }
    }
  }
  &-nav-overlay {
    position: absolute;
    top: 0;
    width: calc(100% - 2rem);
    height: 3rem;
    pointer-events: none;
  }
  &-nav {
    pointer-events: all;
  }
  &-popup {
    width: 640px;
    max-width: calc(var(--100vw) - 4rem);
    word-break: keep-all;
    &-textarea {
      background-color: $col-g-l;
      padding: 0.75rem 1rem;
      border: $col-g 1.5px solid;
      border-radius: 0.5rem;
      width: calc(100% - 2rem);
      @include txt-nav-title;
      font-family: "Noto Sans KR";
      line-height: 1.5;
      font-weight: 500;
      color: $col-k;
      resize: none;
      &::placeholder {
        color: $col-g-f;
      }
    }
  }
}

.intro {
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 100rem;
  background-color: $col-r;
  width: 15rem;
  height: 15rem;
  z-index: 1;
  pointer-events: none;
}

.text {
  &-popup {
    &-title {
      @include txt-section-title;
      line-height: 1.5;
      color: $col-k;
      width: 100%;
      text-align: center;
    }
    @include txt-nav-title;
    line-height: 1.5;
    font-weight: 500;
    color: $col-p-f;
    width: 100%;
    text-align: center;
  }
}
