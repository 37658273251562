@import "../util/style.scss";

.frame {
  &-board {
    &-1 {
      display: flex;
      position: absolute;
      align-items: center;
      justify-content: center;
      width: 400px;
      height: 200px;
      // transform: translate(-50%, -50%);
      border-radius: 2rem;
      overflow: hidden;
      box-shadow: 0 19px 50px rgba(0, 0, 0, 0.2), 0 15px 30px rgba(0, 0, 0, 0.1);
      // box-sizing: border-box;
      &.hover {
        border: 5px solid $col-w;
        z-index: 2;
      }
      background-color: $col-g;
    }
  }
  &-modifying-box {
    position: absolute;
    &.e {
      cursor: e-resize;
      width: 2rem;
      height: calc(100% - 4rem);
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      &:hover {
        border-right: 5px solid $col-w;
      }
    }
    &.se {
      cursor: se-resize;
      width: calc(2rem - 5px);
      height: calc(2rem - 5px);
      bottom: 0;
      right: 0;
      border-radius: 0 0 2rem 0;
      &:hover {
        border-right: 5px solid $col-w;
        border-bottom: 5px solid $col-w;
      }
    }
    &.s {
      cursor: s-resize;
      width: calc(100% - 4rem);
      height: 2rem;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      &:hover {
        border-bottom: 5px solid $col-w;
      }
    }
    &.sw {
      cursor: sw-resize;
      width: calc(2rem - 5px);
      height: calc(2rem - 5px);
      bottom: 0;
      left: 0;
      border-radius: 0 0 0 2rem;
      &:hover {
        border-left: 5px solid $col-w;
        border-bottom: 5px solid $col-w;
      }
    }
    &.w {
      cursor: w-resize;
      width: 2rem;
      height: calc(100% - 4rem);
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      &:hover {
        border-left: 5px solid $col-w;
      }
    }
    &.nw {
      cursor: nw-resize;
      width: calc(2rem - 5px);
      height: calc(2rem - 5px);
      top: 0;
      left: 0;
      border-radius: 2rem 0 0 0;
      &:hover {
        border-left: 5px solid $col-w;
        border-top: 5px solid $col-w;
      }
    }
    &.n {
      cursor: n-resize;
      width: calc(100% - 4rem);
      height: 2rem;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      &:hover {
        border-top: 5px solid $col-w;
      }
    }
    &.ne {
      cursor: ne-resize;
      width: calc(2rem - 5px);
      height: calc(2rem - 5px);
      top: 0;
      right: 0;
      border-radius: 0 2rem 0 0;
      &:hover {
        border-right: 5px solid $col-w;
        border-top: 5px solid $col-w;
      }
    }
    &.c {
      cursor: grab;
      width: calc(100% - 4rem);
      height: calc(100% - 4rem);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.image {
  @include no-drag;
  pointer-events: none;
  object-fit: cover;
}
