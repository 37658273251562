@import "../util/style.scss";

.frame-popup {
  height: 50vh;
  overflow-y: scroll;
}

.text-popup {
  & * {
    line-height: 1.6;
    color: #555555;
  }
  & h1 {
    padding: 1rem 0;
    font-size: 1.5rem;
    color: $col-k;
  }
  & h2 {
    padding-top: 1rem;
    font-size: 1.2rem;
    color: $col-p-d;
  }
  & strong {
    color: $col-p-f;
  }
  & p {
    font-weight: 500;
  }
  & li {
    list-style: circle;
  }
}
